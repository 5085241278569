import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PageEfectivoSi = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Prestamos-Efectivo-Si' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Préstamos Personales de Efectivo Si 💸'
          subheader='Descubre cómo acceder a préstamos en efectivo de hasta $1.200.000 en Efectivo Sí, con plazos flexibles y condiciones adaptadas a tus necesidades financieras.'
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="/solicitar-tarjeta-de-credito-santander">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Visa Platinium 🤑 </Button>
        </Link>
        <Link to="/prestamos-personales-santander">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $300.000 💸 </Button>
        </Link>
        <Link to="/solicitar-tarjeta-de-credito-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Tarjeta de Crédito BBVA 🤑 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
           subheader='
           ¿Necesitas dinero en efectivo de forma rápida y sencilla? Efectivo Sí es una opción confiable para obtener préstamos en efectivo en Argentina. 
           En esta nota, te explicaremos cómo solicitar un préstamo en Efectivo Sí, los requisitos necesarios, la documentación requerida, las condiciones de la cuota y las formas de cobro y pago disponibles.
           <br><br>
           Obtén hasta $1.200.000 en efectivo y paga en hasta 60 cuotas fijas
           <br><br>
           Efectivo Sí te brinda la posibilidad de solicitar préstamos en efectivo de hasta $1.200.000, con plazos de devolución de hasta 60 cuotas fijas. Esto te permite adaptar el préstamo a tu capacidad de pago y mantener una planificación financiera adecuada.
           '
        />
      </Stack>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Requisitos y documentación necesaria para solicitar un préstamo en Efectivo Sí, debes cumplir con los siguientes requisitos:
          <br>
          - Edad entre 18 y 83 años y 364 días.<br>
          - Presentar el Documento Nacional de Identidad (DNI) vigente.<br>
          - Además, dependiendo de tu situación laboral, es posible que se requiera documentación complementaria.<br>
          <br>
          Condiciones de la cuota que deberás pagar estará determinado por los siguientes factores:
          <br>
          - Monto solicitado.<br>
          - Plazo de devolución.<br>
          - Tasa de interés asignada.<br>
        
        
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Es importante destacar que estos valores serán determinados mediante una evaluación crediticia. Para conocer el valor exacto de tu cuota, un asesor se pondrá en contacto contigo una vez que tu solicitud sea aprobada.
          <br><br>
Formas de cobro y pago en Efectivo Sí, puedes acceder al dinero solicitado de las siguientes maneras:<br>
- En efectivo en las sucursales de Efectivo Sí.<br>
- A través de una tarjeta recargable emitida por la entidad.<br>
- Mediante una transferencia a tu cuenta de ahorro por CBU.<br>
<br>
Para el pago de tus cuotas, cuentas con diversas opciones:<br>
- Sucursales de Efectivo Sí.<br>
- Redes de pago como RapiPago, Pago Fácil, Link, Provincia NET, Pago Mis Cuentas, Mercado Pago, Cobro Express y Payway.<br>
- Préstamos para Jubilados y Pensionados<br><br>
<br>
Si eres jubilado o pensionado, también puedes acceder a préstamos en efectivo en Efectivo Sí. Las condiciones y montos disponibles son similares, con la posibilidad de obtener hasta $1.300.000 y pagar en hasta 60 cuotas fijas.
<br>
Requisitos y documentación necesaria para jubilados y pensionados:<br>
- Edad entre 18 y 85 años.<br>
- Cobrar la jubilación o pensión a través de Efectivo Sí.<br>
- Presentar el Documento Nacional de Identidad (DNI).<br><br>
Para conocer las tasas vigentes y obtener información más detallada, puedes ingresar al apartado "Costo de Productos" en el sitio web de Efectivo Sí o acercarte a tu sucursal más cercana.
<br>
Solicita tu préstamo en línea de manera sencilla
<br>
Si prefieres solicitar tu préstamo de manera rápida y cómoda desde tu hogar, Efectivo Sí ofrece la opción de registrarte en línea. Los pasos a seguir son los siguientes:
<br>
- Regístrate en el sitio web de Efectivo Sí, proporcionando la información requerida. <br>
- Ten a mano tu DNI, recibo de sueldo y número de CBU, y asegúrate de que tu cámara funcione correctamente. <br>
- Una vez registrado, selecciona la opción "Solicita tu préstamo". <br>
- Sigue las instrucciones para tomar una foto de tu DNI en un lugar bien iluminado y con tu rostro descubierto. <br>
<br><br>
Con estos simples pasos, podrás comenzar el proceso de solicitud de tu préstamo en efectivo.
<br><br>
En resumen, Efectivo Sí te brinda la oportunidad de obtener préstamos en efectivo de hasta $1.200.000, con plazos flexibles y condiciones adaptadas a tus necesidades. Los requisitos son accesibles, y puedes solicitar el préstamo en línea o acercándote a una sucursal. ¡No dudes en aprovechar esta opción para cubrir tus necesidades financieras de manera rápida y confiable!
          
          
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PageEfectivoSi
